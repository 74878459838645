<template>
  <div class="top_fixed">
    <header>
      <div class="wrap flex">
        <button class="sp_menu_btn"><span></span><span></span><span></span>Open Menu</button>
        <h1 id="h_logo">
          <router-link to="/" class="link">
            <div class="logo">複眼経済塾</div>
          </router-link>
        </h1>
        <ul class="h_nav flex">
          <li class="pc_menu">
            <router-link to="/faq/" class="link">よくあるご質問</router-link>
          </li>
          <li class="pc_menu">
            <router-link to="/contacts/" class="link">お問い合わせ</router-link>
          </li>
          <li class="pc_menu" v-if="isUserSignedIn() && isUserJoin()">
            <a href="javascript:void(0)" class="btn search" v-on:click="showSearchMenu()"><span>検索</span></a>
          </li>
          <li class="pc_menu" v-if="isUserSignedIn() && isUserJoin()">
            <router-link
                :to="{ name: 'SnsTimeline', params: {user_id: $route.meta.current_user.id}}"
                class="btn my_timeline link"
                v-if="$route.meta.current_user">
              <span>自分のタイムライン</span>
            </router-link>
          </li>

          <li class="sp_menu txt" v-if="isUserSignedIn() && isUserJoin()">
            <div class="search_btn flex" v-on:click="showSearchMenu()">
              <img src="/common/images/icon/search_basic.svg" alt="サイト内検索">
              <p>検索</p>
            </div>
          </li>

          <li class="sp_menu txt" v-if="isUserSignedIn() && isUserJoin()">
            <router-link to="/calendar" class="calendar flex">
              <img src="/common/images/icon/header_calendar.svg" alt="カレンダー">
              <p>カレンダー</p>
            </router-link>
          </li>

          <li class="sp_menu txt" v-if="isUserSignedIn() && isUserJoin()">
            <router-link :to="{ name: 'SnsFavorites'}" class="favorites flex">
              <img src="/common/images/icon/star_dark.svg" alt="お気に入り">
              <p>お気に入り</p>
            </router-link>
          </li>

          <li class="sp_menu txt" v-if="isUserSignedIn() && isUserJoin()">
            <a href="javascript:void(0)" v-on:click="alerts" class="alert link" v-bind:class="{on: is_alerts}">
              <img src="/common/images/icon/bell.svg" alt="通知">
              <p>通知</p>
            </a>
          </li>

          <li v-if="isUserSignedIn()">
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="btn user login link">
              <i class="user_icon">
                <img v-bind:src="$route.meta.current_user.profile_image_url" v-if="$route.meta.current_user" alt="">
              </i><span></span>
              <p>マイページ</p>
            </router-link>
          </li>
          <li v-if="!isUserSignedIn()">
            <router-link :to="{ name: 'UsersSignIn'}" class="btn user link">
              <i class="user_icon"><img src="/common/images/icon/h_login.svg" alt=""></i><span></span>
              <p>マイページ</p>
            </router-link>
          </li>

          <li class="pc_menu" v-if="isUserSignedIn() && isUserJoin()">
            <router-link to="/calendar" class="calendar flex">
              <img src="/common/images/icon/header_calendar.svg" alt="カレンダー">
              <p>カレンダー</p>
            </router-link>
          </li>

          <li class="pc_menu ml10" v-if="isUserSignedIn() && isUserJoin()">
            <router-link :to="{ name: 'SnsFavorites'}" class="favorites flex">
              <img src="/common/images/icon/star_dark.svg" alt="お気に入り">
              <p>お気に入り</p>
            </router-link>
          </li>

          <li class="pc_menu" v-if="isUserSignedIn() && isUserJoin()">
            <a href="javascript:void(0)" v-on:click="alerts" class="alert flex" v-bind:class="{on: is_alerts}">
              <img src="/common/images/icon/bell.svg" alt="通知">
              <p>通知</p>
            </a>
          </li>

        </ul>
      </div>
    </header>

    <nav>
      <div id="dl-menu" class="dl-menuwrapper flex">
        <div class="btn_box flex">
          <button class="sp_menu_btn close"><span></span><span></span><span></span></button>
          <div class="search_btn" v-on:click="showSearchMenu()" v-if="isUserSignedIn() && isUserJoin()"></div>
        </div>
        <ul class="dl-menu flex">
          <template v-if="isUserJoin()">
            <li class="ttl">自分のタイムラインの確認と投稿はこちら</li>
            <li class="time_line">
              <router-link
                  :to="{ name: 'SnsTimeline', params: {user_id: $route.meta.current_user.id}}"
                  class="btn my_timeline link"
                  v-if="$route.meta.current_user">
                <span>自分のタイムライン</span>
              </router-link>
            </li>
          </template>
          <li class="ttl">ご登録情報の確認/変更などはこちら</li>
          <li class="my_page">
            <router-link :to="{ name: 'UsersCustomerAccount'}" class="flex link">
              <div class="user_icon" v-if="isUserSignedIn()">
                <img v-bind:src="$route.meta.current_user.profile_image_url" v-if="$route.meta.current_user" alt="">
              </div>
              <div class="user_icon" v-if="!isUserSignedIn()">
                <img src="/common/images/icon/h_login.svg" alt="">
              </div>
              <p>マイページ</p>
            </router-link>
          </li>
          <li class="ttl">メニュー</li>
          <li class="trg link" v-bind:class="{on: nav_category === 'home', disabled: !isUserJoin()}">
            <router-link @click.native="closeAllMenu()" :to="{ name: 'UsersCustomerTop'}" class="link">トップ</router-link>
          </li>
          <li class="trg sp_menu" v-bind:class="{'open': show_top_sp_menu}" v-if="false">
            <a href="javascript:void(0)" v-on:click="showMenuTopSp()">トップ</a>
            <transition>
              <ul class="mega_menu">
                <li class="menu_wrap">
                  <ul class="flex">
                    <div class="sub_ttl"><span>目次</span></div>
                    <li class="sub_menu flex">
                      <div class="sub_menu_box">
                        <div class="menu_list">
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#office_cts'}" class="link">講義一覧
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#content_cts'}" class="link">最新講義のタイムライン
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#social_cts'}" class="link">
                            テーマ別グループの<br>タイムライン
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#timeline_cts'}" class="link">複眼SNS
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#notice_cts'}" class="link">事務局通信のタイムライン
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#today_event'}" class="link">本日開催の研修会
                          </router-link>
                          <router-link @click.native="closeAllMenu()" :to="{ name: 'UsersCustomerTop', hash: '#event'}"
                                       class="link">イベントのスケジュール一覧
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#workshop_cts'}" class="link">
                            イベント参加者専用スペース<br>タイムライン
                          </router-link>
                          <router-link @click.native="closeAllMenu()" :to="{ name: 'UsersCustomerTop', hash: '#how_to'}"
                                       class="link">複眼経済塾のはじめかた
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#activities'}" class="link">複眼経済塾の活動
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#online_shop'}" class="link"
                                       v-if="false">ショップ
                          </router-link>
                          <router-link @click.native="closeAllMenu()"
                                       :to="{ name: 'UsersCustomerTop', hash: '#sns_follow'}" class="link" v-if="false">
                            フォローのおすすめ
                          </router-link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </transition>
          </li>
          <li class="trg" v-bind:class="{open: show_content_menu, on: nav_category === 'content', disabled: this.$route.meta.current_user && !isUserJoin() && !$route.meta.current_user.paid_join}">
            <a href="javascript:void(0)" v-on:click="showContentMenu()">講義</a>
            <transition>
              <ul class="mega_menu" v-if="show_content_menu">
                <li class="menu_wrap">
                  <div class="colse_btn" v-on:click="showContentMenu()"></div>
                  <ul class="flex">
                    <div class="sub_ttl"><span>講義</span></div>
                    <li class="new_cts flex">
                      <router-link v-for="post in content_menu.posts" :key="post.id"
                                   @click.native="closeAllMenu()"
                                   :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: post.sns_space_id, room_id: post.sns_space_room_id}, hash: `#post-${post.id}`}"
                                   class="new_box link">
                        <div class="head flex">
                          <div class="thumb"><img v-bind:src="post.user.profile_image_url" alt=""></div>
                          <div class="info">
                            <div class="cts_name" v-if="post.space_title === post.room_title">
                              <span>{{ post.room_title }}</span>
                            </div>
                            <div class="cts_name" v-else>
                              <span>{{ post.room_title }}</span>
                            </div>
                            <div class="date">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                          </div>
                        </div>
                        <div class="contents">{{ post.description.substring(0, 52) }}</div><!--文字数制限 52文字-->
                      </router-link>
                    </li>
                    <li class="sub_menu kougi flex mb0">
                      <div class="menu_information flex">
                        <div class="rec"><span class="recommend">は必聴オススメ講義</span></div>
                        <div class="class_tag"><p>ご覧いただける講義</p><span class="c01">本科プラスの方</span><span class="c02">本科プラス・本科の方</span><span class="c03">本科プラス・本科・予科の方</span><span class="c04">学生科の方</span></div>
                      </div>

                      <template v-for="space in content_menu.navi_categories">
                        <div class="sub_menu_box colmn_1" v-if="space.rooms.length > 1" :key="space.id">
                          <div class="header flex">
                            <div class="menu_icon">
                              <img v-bind:src="space.image_file_url" alt="" class="menu_icon">
                            </div>
                            <div class="menu_ttl flex">
                              <h3>{{ space.title }}</h3>
                              <p class="pc">{{ space.description }}</p>
                            </div>
                            <p class="sp cat_txt">{{ space.description }}</p>
                          </div>
                          <div class="menu_list">
                            <router-link v-for="room in space.rooms" :key="room.id"
                                         v-bind:class="{disabled: !room.readable, plus_menu: room.only_regular_plus, recommend: room.navi_recommend, student_menu: room.rank_student}"
                                         @click.native="closeAllMenu()"
                                         class="link"
                                         :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: room.sns_space_id, room_id: room.id}}">
                              {{ room.title }}

                              <!-- TODO： 上原さん -->
                              <div class="badge_type">
                                <span v-bind:class="'type_' + ranksClass(room)">{{ ranksString(room) }}</span>
                              </div>

                              <div class="badge" v-if="false">
                                <span class="c01" v-if="room.rank_regular_plus"></span><!--本科プラス-->
                                <span class="c02" v-if="room.rank_regular || room.rank_regular_default"></span><!--本科-->
                                <span class="c03" v-if="room.rank_preparatory"></span><!--予科-->
                                <span class="c04" v-if="room.rank_student"></span><!--学生-->
                              </div>
                            </router-link>
                          </div>
                        </div>

                        <div class="sub_menu_box" v-if="space.rooms.length === 1" :key="space.id">
                          <div class="header flex">
                            <div class="menu_icon"><img v-bind:src="space.image_file_url" alt="" class="menu_icon"></div>
                            <div class="menu_ttl flex">
                              <router-link v-for="room in space.rooms" :key="room.id"
                                           v-bind:class="{disabled: !room.readable, plus_menu: room.only_regular_plus, recommend: room.navi_recommend, student_menu: room.rank_student}"
                                           @click.native="closeAllMenu()"
                                           class="link"
                                           :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: room.sns_space_id, room_id: room.id}}">
                                {{ space.title }}
                              </router-link>
                            </div>
                          </div>
                          <div class="menu_list">
                            <router-link v-for="room in space.rooms" :key="room.id"
                                         v-bind:class="{disabled: !room.readable, plus_menu: room.only_regular_plus, recommend: room.navi_recommend, student_menu: room.rank_student}"
                                         @click.native="closeAllMenu()"
                                         class="link"
                                         :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: room.sns_space_id, room_id: room.id}}">
                              {{ room.title }}

                              <!-- TODO： 上原さん -->
                              <div class="badge_type">
                                <span v-bind:class="'type_' + ranksClass(room)">{{ ranksString(room) }}</span>
                              </div>

                              <div class="badge" v-if="false">
                                <span class="c01" v-if="room.rank_regular_plus"></span><!--本科プラス-->
                                <span class="c02" v-if="room.rank_regular || room.rank_regular_default"></span><!--本科-->
                                <span class="c03" v-if="room.rank_preparatory"></span><!--予科-->
                                <span class="c04" v-if="room.rank_student"></span><!--学生-->
                              </div>
                            </router-link>
                          </div>
                        </div>
                      </template>
                    </li>
                    <li class="sub_menu kougi flex">
                      <template v-for="space in content_menu.other_spaces">
                        <div class="sub_menu_box"
                             v-if="space.rooms.length === 1 && space.access_key !== 'contents_archive'" :key="space.id">
                          <div class="header flex">
                            <div class="menu_icon">
                              <img v-bind:src="space.image_file_url" alt="" class="menu_icon">
                            </div>
                            <div class="menu_ttl flex">
                              <router-link v-bind:class="{disabled: !space.rooms[0].readable}"
                                           @click.native="closeAllMenu()"
                                           class="link"
                                           :to=" space.access_key === 'contents_archive' ? { name: 'Archives'} : {
                                             name: 'SnsSpace', params: {nav_category: 'content', space_id: space.id, room_id: space.rooms[0].id}}">
                                {{ space.rooms[0].title }}

                                <!-- TODO： 上原さん -->
                                <div class="badge_type">
                                  <span v-bind:class="'type_' + ranksClass(space.rooms[0])">{{ ranksString(space.rooms[0]) }}</span>
                                </div>

                                <div class="badge" v-if="false">
                                  <span class="c01" v-if="space.rooms[0].rank_regular_plus"></span><!--本科プラス-->
                                  <span class="c02" v-if="space.rooms[0].rank_regular || room.rank_regular_default"></span><!--本科-->
                                  <span class="c03" v-if="space.rooms[0].rank_preparatory"></span><!--予科-->
                                  <span class="c04" v-if="space.rooms[0].rank_student"></span><!--学生-->
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="sub_menu_box">
                        <router-link :to="{ name: 'Archives'}" class="archives_btn"><span>全講義検索</span>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="menu_close" v-on:click="showContentMenu()"><span><img
                    src="/common/images/icon/megamenu_close.svg" alt="閉じる"></span></li>
              </ul>
            </transition>
          </li>

          <li class="trg" v-bind:class="{open: show_event_menu, on: nav_category === 'event'}">
            <a href="javascript:void(0)" v-on:click="showEventMenu()">イベント申し込み</a>
            <transition>
              <ul class="mega_menu" v-if="show_event_menu">
                <li class="menu_wrap">
                  <div class="colse_btn" v-on:click="showEventMenu()"></div>
                  <ul class="flex">
                    <div class="sub_ttl"><span>イベント申し込み</span></div>
                    <!-- MILLION560 -->
                    <li class="new_cts flex">
                      <!--
                      表示ルール
                      ・月例会 or 研修会 or 合宿 の参加できる/できない、募集中/募集終了 関係無く直近から6件のスケジュールを表示
                      ・募集を終了したら終了テキストを表示
                      ・開催日を過ぎたら非表示になる
                      -->

                      <template v-for="model in workshop_menu.all_events">
                        <template v-if="model.category">
                          <router-link :to="{ name: 'WorkshopShow', params: {category: model.category, id: model.id} }"
                                       @click.native="closeAllMenu()"
                                       v-bind:class="{end_event: model.close}"
                                       class="new_box link" :key="'workshop-' + model.id">
                            <div class="head flex">
                              <div class="thumb">
                                <img src="/common/images/mmicon_tour.png" v-if="model.category === 'camp_camp' || model.category === 'camp_tour'" v-bind:alt="model.category_name">
                                <img src="/common/images/mmicon_kenshu.png" v-else v-bind:alt="model.category_name">
                              </div>
                              <div class="info">
                                <div class="cts_name"><span>{{ model.category_name }}</span></div>
                                <div class="date">{{ moment(model.open_date, 'YYYY/MM/DD') }} {{ model.open_time }}</div>
                                <ul class="tag_list"><!-- 参加可能なクラス -->
                                  <li class="c01" v-if="model.rank_regular_plus">本科プラス</li>
                                  <li class="c02" v-if="model.rank_regular">本科</li>
                                  <li class="c03" v-if="model.rank_preparatory">予科</li>
                                  <li class="c04" v-if="model.rank_general">一般</li>
                                  <li class="c05" v-if="model.rank_student">学生科</li>
                                  <li class="c06" v-if="false /*model.rank_corporation*/">法人科</li>
                                </ul>
                                <p class="end_txt">募集定員に達しました。</p>
                              </div>
                            </div>
                            <div class="contents">{{ model.title }}</div><!-- 詳細ページに反映されてるタイトル -->
                          </router-link>
                        </template>

                        <template v-else>
                          <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }"
                                       @click.native="closeAllMenu()"
                                       v-bind:class="{end_event: model.close}"
                                       class="new_box link" :key="'getsurei-' + model.id">
                            <div class="head flex">
                              <div class="thumb"><img src="/common/images/mmicon_getsurei.png" alt="月例会"></div>
                              <div class="info">
                                <div class="cts_name"><span>月例会</span></div>
                                <div class="date">{{ moment(model.open_date, 'YYYY/MM/DD') }} {{ model.open_time }}</div>
                                <ul class="tag_list"><!-- 参加可能なクラス -->
                                  <li class="c01" v-if="model.rank_regular_plus">本科プラス</li>
                                  <li class="c02" v-if="model.rank_regular">本科</li>
                                  <li class="c03" v-if="model.rank_preparatory">予科</li>
                                  <li class="c04" v-if="model.rank_general">一般</li>
                                  <li class="c05" v-if="model.rank_student">学生科</li>
                                  <li class="c06" v-if="false /*model.rank_corporation*/">法人科</li>
                                </ul>
                                <p class="end_txt">募集定員に達しました。</p>
                              </div>
                            </div>
                            <div class="contents">{{ model.title }}</div><!-- 詳細ページに反映されてるタイトル -->
                          </router-link>
                        </template>
                      </template>
                    </li>
                  </ul>
                </li>
                <li class="menu_wrap">
                  <div class="event_cat_list flex">
                    <div class="event_cat">
                      <h4 class="min_ttl">月例会</h4>
                      <router-link :to="{ name: 'GetsureiList'}">月例会スケジュール</router-link>
                    </div>
                    <div class="event_cat">
                      <h4 class="min_ttl">ワークショップ</h4>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_shikiho'}}"
                                   @click.native="closeAllMenu()">四季報10倍ワークショップ
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_nikkei'}}"
                                   @click.native="closeAllMenu()">日経マジ読みワークショップ
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_shiome'}}"
                                   @click.native="closeAllMenu()">四季報から潮目を読むワークショップ
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_screening'}}"
                                   @click.native="closeAllMenu()">スクリーニングワークショップ
                      </router-link>
                    </div>
                    <div class="event_cat">
                      <h4 class="min_ttl">合宿・イベント</h4>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_irmtg'}}"
                                   @click.native="closeAllMenu()">複眼IRミーティング
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'camp_camp'}}"
                                   @click.native="closeAllMenu()">複眼合宿
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'camp_tour'}}"
                                   @click.native="closeAllMenu()">複眼ツアー
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_online'}}"
                                   @click.native="closeAllMenu()">オンライン懇親会
                      </router-link>
                      <router-link :to="{ name: 'WorkshopList', params: {category: 'workshop_event'}}"
                                   @click.native="closeAllMenu()">イベント
                      </router-link>
                    </div>
                  </div>
                </li>
                <li class="btnBox bdr_top">
                  <router-link :to="{ name: 'SnsWorkshops'}" @click.native="closeAllMenu()" class="txt_link">
                    <span>イベント参加者専用スペースはこちら</span>
                  </router-link>
                </li>
                <li class="menu_close" v-on:click="showEventMenu()"><span><img
                    src="/common/images/icon/megamenu_close.svg" alt="閉じる"></span></li>
              </ul>
            </transition>
          </li>

          <li class="trg"
              v-bind:class="{open: show_notice_menu, on: nav_category === 'notice', disabled: !isUserJoin()}">
            <a href="javascript:void(0)" v-on:click="showNoticeMenu()">事務局</a>
            <transition>
              <ul class="mega_menu" v-if="show_notice_menu">
                <li class="menu_wrap">
                  <div class="colse_btn" v-on:click="showNoticeMenu()"></div>
                  <ul class="flex">
                    <div class="sub_ttl"><span>事務局</span></div>
                    <li class="new_cts flex">
                      <router-link v-for="(post, key) in notice_menu.posts" :key="key"
                                   @click.native="closeAllMenu()"
                                   :to="{ name: 'SnsSpace', params: {nav_category: 'notice', space_id: post.sns_space_id, room_id: 0}, hash: `#post-${post.id}`}"
                                   class="new_box link">
                        <div class="head flex">
                          <div class="thumb"><img v-bind:src="post.user.profile_image_url"
                                                  v-if="$route.meta.current_user" alt=""></div>
                          <div class="info">
                            <div class="cts_name">
                              <span>{{ post.user.sns_user_name }}</span><span v-if="false">{{ post.space_title }}</span>
                            </div>
                            <div class="date">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                          </div>
                        </div>
                        <div class="contents">{{ post.description.substring(0, 52) }}</div><!--文字数制限 52文字-->
                      </router-link>
                    </li>
                    <li class="event_cat_list flex">
                      <div class="event_cat">
                        <h4 class="min_ttl">事務局からのご案内</h4>
                        <router-link
                            @click.native="closeAllMenu()"
                            :to="{ name: 'SnsSpace', params: {nav_category: 'notice', space_id: notice_menu.posts[0].sns_space_id, room_id: 0}}"
                        >
                          事務局通信
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/class_change/">クラス変更について</router-link>
                        <router-link @click.native="closeAllMenu()" to="/class_matrix/">クラス比較表</router-link>
                        <router-link @click.native="closeAllMenu()" to="/shikiho_online/">会社四季報ONLINE塾生割引
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/calendar_guide/">カレンダーを同期するには
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/nintei_shikaku/">複眼経済塾認定資格
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/lp/" target="_blank"><span class="icon link">複眼経済塾とは</span>
                        </router-link>
                      </div>
                      <div class="event_cat">
                        <h4 class="min_ttl">読み物</h4>
                        <router-link @click.native="closeAllMenu()" to="/seiji_interview/">渡部清二塾長インタビュー
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/toyokeizai/">週刊東洋経済 掲載記事
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/workshop_review/">月例会・研修会  受講いただいた塾生の声
                        </router-link>
                      </div>
                      <div class="event_cat">
                        <h4 class="min_ttl">その他</h4>
                        <router-link @click.native="closeAllMenu()" to="/faq/">よくあるご質問</router-link>
                        <router-link @click.native="closeAllMenu()" to="/contacts/">お問い合わせ</router-link>
                        <router-link @click.native="closeAllMenu()" to="/rule/">ご利用規約</router-link>
                        <router-link @click.native="closeAllMenu()" to="/privacy-policy/">個人情報保護方針</router-link>
                        <router-link @click.native="closeAllMenu()" to="/company/">会社概要</router-link>
                        <router-link @click.native="closeAllMenu()" to="/sitemap_member/">サイトマップ</router-link>
                      </div>
                    </li>
                    <li class="btnBox bdr_top">
                      <div class="event_cat_list flex">
                        <div class="event_cat">
                          <h4 class="min_ttl">
                            <router-link @click.native="closeAllMenu()" to="/instructor_info/">講師陣</router-link>
                          </h4>
                        </div>
                        <div class="event_cat">
                          <h4 class="min_ttl">
                            <router-link @click.native="closeAllMenu()" to="/class_change/">クラス変更について</router-link>
                          </h4>
                        </div>
                        <div class="event_cat">
                          <h4 class="min_ttl">
                            <router-link @click.native="closeAllMenu()" to="/contacts/">お問い合わせ</router-link>
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="btnBox" v-if="false">
                  <router-link
                      @click.native="closeAllMenu()"
                      :to="{ name: 'SnsSpace', params: {nav_category: 'notice', space_id: notice_menu.posts[0].sns_space_id, room_id: 0}}"
                      class="submit light link">
                    事務局通信
                  </router-link>
                </li>
                <li class="menu_close mt50" v-on:click="showNoticeMenu()"><span><img
                    src="/common/images/icon/megamenu_close.svg" alt="閉じる"></span></li>
              </ul>
            </transition>
          </li>
          <li class="sp_menu link" v-bind:class="{disabled: !isUserJoin()}" v-if="false">
            <router-link
                @click.native="closeAllMenu()"
                :to="{ name: 'SnsSpace', params: {nav_category: 'notice', space_id: 8, room_id: 0}}">
              事務局通信
            </router-link>
          </li>

          <li class="trg"
              v-bind:class="{open: show_social_menu, on: nav_category === 'social', disabled: !isUserJoin()}">
            <a href="javascript:void(0)" v-on:click="showSocialMenu()">テーマ別グループ</a>
            <transition>
              <ul class="mega_menu" v-if="show_social_menu">
                <li class="menu_wrap">
                  <div class="colse_btn" v-on:click="showSocialMenu()"></div>
                  <ul class="flex">
                    <div class="sub_ttl"><span>テーマ別グループ</span></div>
                    <li class="new_cts flex">
                      <router-link v-for="(post, key) in social_menu.posts" :key="key"
                                   @click.native="closeAllMenu()"
                                   :to="{ name: 'SnsSpace', params: {nav_category: 'social', space_id: post.sns_space_id, room_id: 0}, hash: `#post-${post.id}`}"
                                   class="new_box link">
                        <div class="head flex">
                          <div class="thumb"><img v-bind:src="post.image_file_url" alt=""></div>
                          <div class="info">
                            <div class="cts_name"><span>{{ post.space_title }}</span>
                            </div>
                            <div class="date">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                          </div>
                        </div>
                        <div class="contents">{{ post.description.substring(0, 52) }}</div><!--文字数制限 52文字-->
                      </router-link>
                    </li>
                    <li class="sub_menu flex">

                      <div class="sub_menu_box" v-for="(space, key) in social_menu.spaces" :key="key">
                        <router-link
                            @click.native="closeAllMenu()"
                            class="header flex link"
                            :to="{ name: 'SnsSpace', params: {nav_category: 'social', space_id: space.id, room_id: 0}}">

                          <div class="menu_icon">
                            <img v-bind:src="space.image_file_url" alt="" class="menu_icon">
                          </div>
                          <div class="stdt_menu">
                            <h3>{{ space.title }}</h3>
                            <p>{{ space.description_text.substring(0, 36) }}</p>
                          </div>
                          <p class="sp">{{ space.description_text.substring(0, 52) }}</p>
                        </router-link>
                      </div>
                    </li>
                    <!-- MILLION560 -->
                    <li class="btnBox bdr_top">
                      <div class="event_cat_list flex">
                        <div class="event_cat">
                          <h4 class="min_ttl">
                            <router-link :to="{name:'SnsSocials'}"
                                         @click.native="closeAllMenu()">
                              テーマ別グループ参加状況
                            </router-link>
                          </h4>
                        </div>
                        <div class="event_cat">
                          <h4 class="min_ttl">
                            <router-link :to="{name:'SnsWorkshops'}"
                                         @click.native="closeAllMenu()">
                              イベント参加者専用スペース
                            </router-link>
                          </h4>
                        </div>
                        <div class="event_cat">
                          <h4 class="min_ttl"><a
                              href="https://millioneyescoltd.my.webex.com/millioneyescoltd.my/j.php?MTID=m4fe8ca2cd74ef7704cebeba1d5199a7d"
                              target="_blank">複眼自由会議室</a></h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="menu_close" v-on:click="showSocialMenu()"><span><img
                    src="/common/images/icon/megamenu_close.svg" alt="閉じる"></span></li>
              </ul>
            </transition>
          </li>

          <!--メディア -->
          <li class="trg"
              v-bind:class="{open: show_media_menu, on: nav_category === 'media'}">
            <a href="javascript:void(0)" v-on:click="showMedialMenu()">メディア</a>
            <transition>
              <ul class="mega_menu" v-if="show_media_menu">
                <li class="menu_wrap">
                  <div class="colse_btn" v-on:click="showMedialMenu()"></div>
                  <ul class="flex">
                    <div class="sub_ttl"><span>メディア<br class="sp">各種インフォメーション</span></div>
                    <!-- MILLION560 -->
                    <li class="new_cts flex">
                      <router-link v-for="(post, key) in media_menu.posts" :key="key"
                                   @click.native="closeAllMenu()"
                                   :to="{ name: 'SnsSpace', params: {nav_category: 'media', space_id: post.sns_space_id, room_id: 0}, hash: `#post-${post.id}`}"
                                   class="new_box link">
                        <div class="head flex">
                          <div class="thumb"><img v-bind:src="post.user.profile_image_url"
                                                  v-if="$route.meta.current_user" alt=""></div>
                          <div class="info">
                            <div class="cts_name">
                              <span>{{ post.user.sns_user_name }}</span><span v-if="false">{{ post.space_title }}</span>
                            </div>
                            <div class="date">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                          </div>
                        </div>
                        <div class="contents">{{ post.description.substring(0, 52) }}</div><!--文字数制限 52文字-->
                      </router-link>
                    </li>
                    <!-- MILLION560 end -->
                    <li class="menu_wrap">
                      <div class="default_menu_list flex">
                        <router-link @click.native="closeAllMenu()" :to="{ name: 'SnsSpace', params: {nav_category: 'media', space_id: sns_media_id(), room_id: 0}}"><h4 class="min_ttl">メディア</h4>
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/instructor/"><h4 class="min_ttl">講演</h4>
                        </router-link>
                        <router-link @click.native="closeAllMenu()" to="/corporate_training/"><h4 class="min_ttl">
                          企業研修</h4></router-link>
                        <router-link @click.native="closeAllMenu()" to="/books/"><h4 class="min_ttl">当社書籍</h4>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="menu_close" v-on:click="showMedialMenu()"><span><img
                    src="/common/images/icon/megamenu_close.svg" alt="閉じる"></span></li>
              </ul>
            </transition>
          </li>

          <li class="">
            <router-link to="/company/">会社概要</router-link>
          </li>

          <li class="trg link" v-bind:class="{on: nav_category === 'shop'}" v-if="true">
            <router-link :to="{ name: 'ShoppingArticles'}" class="link">ショップ</router-link>
          </li>
          <li class="trg link" v-bind:class="{on: nav_category === 'shop'}" v-else>
            <a href="https://millioneyes.shop/" target="_blank">ショップ</a>
          </li>

          <li class="ttl">その他</li>
          <li class="sp_menu">
            <router-link to="/faq/" class="link">よくあるご質問</router-link>
          </li>

          <li class="sp_menu">
            <router-link to="/contacts/" class="link">お問い合わせ</router-link>
          </li>
          <li class="ttl">ご利用に関するお問い合わせはこちら</li>
          <li class="tel_num">
            <a href="tel:0362808451" class="flex">
              <div class="icon"></div>
              <div class="cts">
                <p class="number">03-6280-8451</p>
                <p class="red bold">タップで自動的に電話を発信します</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <FeeFailedFooter v-if="showFeeFailed()" :bank_fee="bank_fee"/>

    <transition>
      <SearchMenu v-if="show_search_menu" v-on:closed="showSearchMenu()"/>
    </transition>

    <IndexMenu v-if="$route.name === 'UsersCustomerTop'"/>
    <FeeFailedModal v-if="show_fee_failed_modal" :bank_fee="bank_fee" v-on:cancel="show_fee_failed_modal=false"/>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import IndexMenu from '@/views/users/customer/elements/top/_index_menu'
import SearchMenu from '@/elements/SearchMenu'
import FeeFailedModal from '@/components/FeeFailedModal'
import FeeFailedFooter from '@/components/FeeFailedFooter'

export default {
  mixins: [Libraries],
  components: {
    IndexMenu,
    SearchMenu,
    FeeFailedModal,
    FeeFailedFooter
  },
  data() {
    return {
      nav_category: null,
      show_content_menu: false,
      show_event_menu: false,
      show_notice_menu: false,
      show_social_menu: false,
      show_search_menu: false,
      show_top_sp_menu: false,
      show_media_menu: false,

      content_menu: null,
      notice_menu: null,
      workshop_menu: null,
      social_menu: null,
      media_menu: null,
      is_alerts: false,
      show_fee_failed_modal: false,
      last_show_fee_failed_modal_time: 0,
      bank_fee: false
    }
  },
  watch: {
    $route(to, /* from */) {
      if (to.name === 'UsersCustomerTop') {
        this.nav_category = 'home'
      } else if (to.name === 'Shop') {
        this.nav_category = 'shop'
      } else if (this.$route.params.nav_category) {
        this.nav_category = this.$route.params.nav_category
      } else {
        this.nav_category = null
      }
      this.closeAllMenu()

      // 月謝決済エラー：モーダル
      this.showFeeFailedModal();
    }
  },

  methods: {
    showContentMenu() {
      if (this.show_content_menu) {
        this.closeAllMenu();
      } else {
        this.axios
            .get(`${this.env.api_base_url}sns/posts_digest_content.json`)
            .then(response => {
              this.content_menu = response.data
              this.closeAllMenu();
              this.show_content_menu = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
      }
    },
    showEventMenu() {
      if (this.show_event_menu) {
        this.closeAllMenu()
      } else {
        this.axios
            .get(`${this.env.api_base_url}sns/posts_digest_workshop.json`)
            .then(response => {
              this.workshop_menu = response.data
              // 研修会と月例会を時系列でまとめる
              this.workshop_menu.all_events = response.data.events.workshops
              this.workshop_menu.all_events = this.workshop_menu.all_events.concat(response.data.events.monthly_lives)
              this.workshop_menu.all_events.sort((a, b) => {
                if (a.open_date < b.open_date) return -1
                if (a.open_date > b.open_date) return 1
                return 0
              })
              // 最大 6件
              if (this.workshop_menu.all_events.length > 6) {
                this.workshop_menu.all_events.length = 6
              }
              this.closeAllMenu();
              this.show_event_menu = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
      }
    },
    showNoticeMenu() {
      if (this.show_notice_menu) {
        this.closeAllMenu()
      } else {
        this.axios
            .get(`${this.env.api_base_url}sns/posts_digest_notice.json`)
            .then(response => {
              this.notice_menu = response.data
              this.closeAllMenu();
              this.show_notice_menu = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
      }
    },
    showSocialMenu() {
      if (this.show_social_menu) {
        this.closeAllMenu()
      } else {
        this.axios
            .get(`${this.env.api_base_url}sns/posts_digest_social.json`)
            .then(response => {
              this.social_menu = response.data
              this.closeAllMenu();
              this.show_social_menu = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
      }
    },
    showMedialMenu() {
      if (this.show_media_menu) {
        this.closeAllMenu()
      } else {
        this.axios
            .get(`${this.env.api_base_url}sns/posts_digest_media.json`)
            .then(response => {
              this.media_menu = response.data
              this.closeAllMenu()
              this.show_media_menu = true
            })
            .catch((response) => {
              this.errorAjax(response)
            })
      }
    },
    showShopMenu() {
      // TODO: shop へ遷移
    },

    showMenuTopSp() {
      if (this.show_top_sp_menu) {
        this.closeAllMenu()
      } else {
        setTimeout(() => {
          this.closeAllMenu();
          this.show_top_sp_menu = true
        }, 200)
      }
    },
    showSearchMenu() {
      if (this.$route.name !== 'SnsSearch') {
        if (this.show_search_menu) {
          this.show_search_menu = false
        } else {
          this.show_search_menu = true
        }
      }
    },
    closeAllMenu() {
      this.show_content_menu = this.show_event_menu = this.show_notice_menu = this.show_social_menu = this.show_media_menu = this.show_top_sp_menu = false
    },
    alerts() {
      this.is_alerts = false
      if (this.$route.name === 'SnsAlerts') { // reload
        this.$router.push({name: 'SnsAlerts', query: {reload: new Date().getTime()}})
      } else {
        this.$router.push({name: 'SnsAlerts'})
      }
    },
    alertBelUpdate() {
      this.axios
          .get(`${this.env.api_base_url}sns/is_alerts.json`)
          .then(response => {
            this.is_alerts = response.data.alert
          })
    },
    showFeeFailed() {
      return document.body.classList.contains('fee-failed')
    },
    showFeeFailedModal() {
      // 月謝決済エラー：モーダル
      this.show_fee_failed_modal = false
      if (this.last_show_fee_failed_modal_time < new Date().getTime()) {
        if (this.showFeeFailed()) {
          setTimeout(() => {
            // モーダルは出さない
            // this.show_fee_failed_modal = true
            this.last_show_fee_failed_modal_time = new Date().getTime() + ((60 * 60 * 24) * 1000)
          }, 500)
        }
      }
    },
    ranksString(room) {
      if (room.only_regular_plus) {
        return '本科プラス限定'
      } else if (room.only_student) {
        return '学生科限定'
      } else {
        let ranks = []
        if (room.rank_regular_plus) ranks.push('本科プラス')
        if (room.rank_regular || room.rank_regular_default) ranks.push('本科')
        if (room.rank_preparatory) ranks.push('予科')
        return ranks.join('・')
      }
    },
    ranksClass(room) {
      if (room.only_regular_plus) {
        return 'only_regular_plus'
      } else if (room.only_student) {
        return 'only_student'
      } else {
        let ranks = []
        if (room.rank_regular_plus) ranks.push('plus')
        if (room.rank_regular || room.rank_regular_default) ranks.push('regular')
        if (room.rank_preparatory) ranks.push('preparatory')
        if (room.rank_student) ranks.push('student')
        return ranks.join('_')
      }
    }
  },
  mounted() {
    let _scrollpos = 0;
    if (!window.matchMedia('(min-width: 768px)').matches) {
      window.$(".sp_menu_btn").on("click", function () {
        if (window.$(this).hasClass('close')) {
          window.$("body").removeClass('menu_open');
          window.$("nav").removeClass('open');
          window.$('body').removeClass('fixed').css({'top': 0});
          window.scrollTo(0, _scrollpos);
        } else {
          window.$("nav").addClass('open');
          _scrollpos = window.$(window).scrollTop();
          window.$('body').addClass('fixed').css({'top': -_scrollpos});
        }
      });
      window.$(".dl-menu li").on("click", function () {
        if (window.$(this).hasClass('open')) {
          window.$(".dl-menu li").removeClass('open');
        } else {
          if (window.$(this).hasClass('link')) {
            //window.$('.sp_menu_btn').trigger('click');
          } else {
            window.$(this).addClass('open');
          }
        }
      });
      window.$(document).click(function (event) {
        if (!window.$(event.target).closest('.mega_menu .menu_wrap').length) {
          window.$(".dl-menu li.open").removeClass('open');
        }
      });
    }
    window.$('a').click(function () {
      if (window.$(this).hasClass('link')) {
        //this.closeAllMenu()
        window.$('.sp_menu_btn').trigger('click');
      }
    });

    this.showFeeFailedModal()
  },

  created() {
    window.addEventListener('click', () => {
      this.closeAllMenu()
    });
    const current_user = JSON.parse(localStorage.getItem('user'))
    this.bank_fee = current_user.rank_bank_fee
    if (current_user && current_user.is_join) {
      // update alert bell
      this.alertBelUpdate()
      window.setInterval(() => this.alertBelUpdate(), process.env.VUE_APP_SNS_ALERT_UPDATE_INTERVAL || 30000)
    }
  }
}
</script>
